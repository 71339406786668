import React from 'react';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import { navigate } from 'gatsby';
import AntiChurnPayWhatYouCan from '~modules/anti-churn/components/AntiChurnPayWhatYouCan';

const seo = {
  seo_title: 'Pay What You Can | Aleph Beta',
  seo_description: '', // FIXME TODO
  seo_keywords: '' // FIXME TODO
};

const PayWhatYouCanInstead = () => (
  <Page>
    <SEO {...seo} />
    <AntiChurnPayWhatYouCan handleCancel={() => navigate('/account/billing/cancel')} />
  </Page>
);

export default PayWhatYouCanInstead;
